import React from 'react';

// Components
import {
  Layout,
  Container,
  NewsFeed,
  GoldWidget,
  Disclaimer,
  ServiceFeed,
} from '../components';

// Data
import data from '../data/pages/live-prices.json';

const LivePrices = () => {
  const content = data.content;

  return (
    <Layout pageContext={data}>
      <Container addSpacers>
        {/* Gold Table */}
        <GoldWidget maxWidth="740px" />

        {/* Gold Feed Graph */}
        <div style={{ width: '100%', height: '578px' }}>
          <iframe
            src="https://gold-feed.com/charts/goldfeed29v9234ltlvl234l66l324/chart.php"
            scrolling="no"
            height="100%"
            width="100%"
            frameBorder="0"></iframe>
        </div>

        {/* Disclaimer */}
        <Disclaimer />

        {/* Service Feed */}
        <ServiceFeed
          headerProps={{ title: 'Browse Our Services' }}
          columnCount={3}
          scrollOnMobile
        />

        {/* News Feed */}
        <NewsFeed />
      </Container>
    </Layout>
  );
};

export default LivePrices;
